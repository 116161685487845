<template>
  <div class="bottom-bar">
    <div class="msg-params">
      <el-dropdown class="param-left" @command="handlePrompt">
        <span>
          {{ promptParams.promptTitle }}<i class="iconfont">&#xe61c;</i>
        </span>
        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              :command="item"
              :class="{ selected: props.promptParams.promptId == item.id }"
              v-for="item in promptArr"
              :key="item.id"
              >{{ item.title }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>

      <el-dropdown class="param-right" @command="handleTemperature">
        <span>
          <i class="iconfont">&#xe61d;</i>
        </span>
        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              :command="item"
              :class="{
                selected: props.promptParams.temperature == item.temperature,
              }"
              v-for="item in temperatureArr"
              :key="item.temperature"
              >{{ item.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="msg-send">
      <div class="msg-box">
        <textarea
          class="msg-input"
          v-model="messageValue"
          :class="{ focus: isFocus }"
          @focus="handleFocus"
          @blur="handleBlur"
          placeholder="输入聊天内容..."
        ></textarea>
        <el-button type="primary" title="发送" @click="sendMsg">
          <i class="iconfont">&#xe652;</i></el-button
        >
      </div>
      <div class="upload" v-if="props.uploadParams.knowledgeID != undefined">
        <el-upload
          :action="uploadUrl"
          :data="props.uploadParams"
          :headers="headers"
          :on-success="handleUpload"
        >
          <i class="iconfont">&#xe60a;</i>
        </el-upload>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, defineEmits } from "vue";
import { getToken, getTemperature } from "@/utils/auth";
import { getPrompts, sendMessage, setPrompt } from "@/api/chat";
import { useRouter } from "vue-router";
const props = defineProps({
  uploadParams: {
    type: Object,
  },
  promptParams: {
    type: Object,
  },
});
const router = useRouter();
const emit = defineEmits(["sendValue"]);
let isFocus = ref(false);
const uploadUrl = process.env.VUE_APP_BASE_URI + "/store/uploadKnowledgeFile";
let headers = {
  Authorization: "Bearer" + getToken(),
  ContentType:
    "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
};
let messageValue = ref("");
let promptArr = ref([]);
let temperatureArr = ref([]);

onMounted(() => {
  temperatureArr.value = JSON.parse(getTemperature());
  getPromptList();
});
function handlePrompt(command) {
  props.promptParams.promptId = command.id;
  props.promptParams.promptTitle = command.title;
  let form = {
    conversationId: router.currentRoute.value.query.id,
    promptId: command.id,
  };
  setPrompt(router.currentRoute.value.query.id, form).then((res) => {
    if (res.data.code == 0) {
    }
  });
}
function handleTemperature(command) {
  props.promptParams.temperature = command.temperature;
  let form = {
    conversationId: router.currentRoute.value.query.id,
    temperature: command.temperature,
  };
  setPrompt(router.currentRoute.value.query.id, form).then((res) => {
    if (res.data.code == 0) {
    }
  });
}

function getPromptList() {
  getPrompts({ conversationId: router.currentRoute.value.query.id }).then(
    (res) => {
      promptArr.value = res.data.data.rows;
    }
  );
}
function handleFocus() {
  isFocus.value = true;
}
function handleBlur() {
  isFocus.value = false;
}
// function getTemperatureList() {
//   getTemperature().then((res) => {
//     temperatureArr.value =res.data.data
//   })
// }
function sendMsg() {
  if (!messageValue.value) {
    return;
  }
  emit("sendValue", messageValue.value);
  messageValue.value = "";
}
function handleUpload(res, file) {
}
</script>
<style scoped>
::v-deep .el-dropdown-menu__item.selected {
  color: #297fff;
}
.bottom-bar {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 10px 15px;
  position: fixed;
  bottom: 0;
}
.msg-params {
  display: flex;
  height: 24px;
  line-height: 24px;
  justify-content: space-between;
  margin: 5px 0;
}
.msg-params .param-left {
  cursor: pointer;
}
.msg-params .param-left i {
  font-size: 12px;
  color: #909090;
  margin-left: 5px;
}
.msg-params .param-left span {
  color: #297fff;
}
.msg-params .param-right {
  margin-right: 38px;
}
.msg-params .param-right i {
  font-size: 14px;
  font-weight: bold;
  color: #909090;
  cursor: pointer;
}

.msg-send {
  display: flex;
  align-items: end;
}
.msg-box {
  flex: 1;
  display: flex;
  background: #ffffff;
  padding: 8px 8px;
  border-radius: 8px;
  position: relative;
}
.msg-box .textarea {
  height: 44px;
  font-size: 14px;
}
.msg-box .el-button {
  padding: 8px;
  position: absolute;
  right: 5px;
  bottom: 5px;
  height: 26px;
  width: 26px;
}

.upload {
  height: 26px;
  line-height: 26px;
  margin-left: 10px;
  margin-bottom: 5px;
}
.upload i {
  font-size: 26px;
  color: #09b0c5;
}
.bottom-bar .msg-input {
  width: calc(100% - 32px);
  height: 22px;
  line-height: 22px;
  background-color: #fff;
  font-size: 14px;
  border: none;
  vertical-align: middle;
  transition: height 0.3s ease-in-out;
}
.bottom-bar .msg-input.focus {
  height: 44px;
}
.bottom-bar .msg-input::placeholder {
  color: #c5c5c5;
}
</style>
