<template>
  <div class="menu-item" :class="{ active: path == 'conversation' }">
    <router-link to="/conversation">
      <i class="iconfont" title="聊天管理">&#xe617;</i>
    </router-link>
  </div>
  <!-- <div class="menu-item" :class="{ active: path == 'knowledge' }">
    <router-link to="/knowledge">
      <i class="iconfont" title="知识库管理">&#xe61a;</i>
    </router-link>
  </div>
  <div class="menu-item" :class="{ active: path == 'digitalMan' }">
    <router-link to="/digitalMan">
      <i class="iconfont" title="数字人">&#xe600;</i>
    </router-link>
  </div>
  <div class="menu-item" :class="{ active: path == 'translation' }">
    <router-link to="/translation">
      <i class="iconfont" title="PDF翻译">&#xe62c;</i>
    </router-link>
  </div> -->
</template>
<script setup>
import { useRouter } from "vue-router";
import { ref } from "vue";
const router = useRouter();
const currentRoute = useRouter().currentRoute.value.path;
const path = ref("conversation");
if (currentRoute == "/conversation") {
  path.value = "conversation";
}
if (currentRoute == "/knowledge") {
  path.value = "knowledge";
}
if (currentRoute == "/digitalMan") {
  path.value = "digitalMan";
}
if (currentRoute == "/translation") {
  path.value = "translation";
}
</script>
<style scoped>
.menu-item {
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.menu-item i {
  color: #808080;
  font-size: 32px;
}
.menu-item.active i {
  color: #297fff;
}
</style>
