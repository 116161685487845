<template>
  <div class="top-bar">
    <div class="user-head" v-if="icons.length > 0">
      <img
        :class="{ small: icons.length > 1 }"
        :src="item ? imgUrl + item : ''"
        v-for="(item, index) in icons.slice(0, 4)"
        :index="index"
        :key="index"
      />
    </div>
    <div class="name">{{ conversationObj.conversationName }}</div>
    <div class="clear" @click="handleClear">清空聊天记录</div>
  </div>
  <!-- <div class="clearfix" style="overflow: scroll"> -->
  <!-- <div style="overflow-y: scroll"> -->
  <div class="messages clearfix" id="messages" ref="mianscroll">
    <!--  操作展示 -->
    <div class="guide-box" v-if="!messageArr.length">
      <h1 class="title">你好，我是华必达</h1>
      <span class="text"
        >作为你的智能伙伴，我既能写文案、想点子，又能陪你聊天、答疑解惑。<br />
        想知道我还能做什么? 想学习如何提问?
        <a>点这里</a>，每天1分钟，激发我的无限潜能!
      </span>
      <div class="tab-title">
        <div>你可以试着问我：</div>
        <div @click="getSpeechList"><i class="iconfont">&#xe634;</i>换一换</div>
      </div>
      <div class="guide-tab">
        <div
          class="tab-box"
          v-for="(item, index) in speechArray"
          :key="index"
          @click="speechClick(item.question)"
        >
          <div>
            <svg class="icon head-portrait" aria-hidden="true">
              <use xlink:href="#icon-weibiaoti-_huabanfuben" />
            </svg>
            {{ item.type }}
          </div>
          <div>{{ item.question }}</div>
        </div>
      </div>
    </div>
    <!-- 对话 -->
    <div
      class="message"
      :class="{
        fromme: item.user_id,
        fromrobot: item.robot_id,
        time: item.time != undefined,
      }"
      v-for="(item, index) in messageArr"
      :index="index"
      :key="index"
    >
      <div class="send-time" v-if="item.time != undefined">
        {{ item.time }}
      </div>
      <div class="loading" v-else-if="item.loading != undefined">
        <div class="point1"></div>
        <div class="point2"></div>
        <div class="point3"></div>
      </div>
      <template v-else>
        <div class="user-head">
          <img
            :src="imgUrl + conversationObj.icon_dict[item.robot_id]"
            v-if="item.robot_id"
          />
          <img src="@/assets/img/user.jpg" v-else />
        </div>
        <div class="content">
          <!-- 正文输出 -->
          <div class="entry-content">
            <!-- <pre v-highlight>
              <code class="language-javascript"  v-html="item.context"></code>
            </pre> -->
            <div
              v-show="!item.type"
              v-highlight
              v-html="item.context"
              id="content"
              ref="contextScroll"
            ></div>
            <div v-show="item.type">
              <a class="pdf-a" :href="item.context" download>
                <div class="box">
                  <img src="@/assets/img/icon08.png" />
                  <div
                    :class="{ 'user-text': item.user_id }"
                    :title="item.fileName"
                  >
                    {{ item.fileName || "翻译文件" }}
                  </div>
                </div>
              </a>
            </div>
          </div>

          <!-- <div v-html="`${item.context}`.replace(/\n/g, '<br/>')"></div> -->
          <div class="source" v-show="item.robot_id && !item.type">
            <!-- <span>消息来源：{{ item.resource }}</span> -->
            <div class="evalution">
              <div>
                <a @click="speechClick('重新生成')" class="refresh">{{
                  item.isGenerate ? "正在生成" : "重新生成"
                }}</a>
              </div>
              <div class="left">
                <i
                  v-if="item.id"
                  class="iconfont"
                  :class="{ active: item.praise }"
                  @click="handleEvaluate(item.id, 'praise')"
                  >&#xe609;</i
                >
                <i
                  v-if="item.id"
                  class="iconfont"
                  :class="{ active: item.trample }"
                  @click="handleEvaluate(item.id, 'trample')"
                  >&#xe60e;</i
                >
                <i
                  class="iconfont"
                  @click="handleEvaluate(item.context, 'copy')"
                  >&#xe633;</i
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
  <!-- </div> -->
  <!-- </div> -->
  <!-- 底部输入框 -->
  <bottom-bar
    :uploadParams="uploadParams"
    :promptParams="promptParams"
    :isRequest="isRequest"
    @sendValue="sendMsg"
    @listValue="getList"
  >
  </bottom-bar>
</template>
<script setup>
import { getToken } from "@/utils/auth";
import { onBeforeUnmount, ref, watch, onMounted, nextTick } from "vue";

// import MarkdownIt from "markdown-it";
import { marked } from "marked";
// import hljs from "highlight.js";
// import "highlight.js/styles/atom-one-dark.css"; //样式
// import Prism from "prismjs"; //导入代码高亮插件的core（里面提供了其他官方插件及代码高亮样式主题，你只需要引入即可）
// import "prismjs/themes/prism-tomorrow.css"; //引入代码高亮主题（这个去node_modules的安装prismjs中找到想使用的主题即可）

import { useRouter } from "vue-router";
import { BottomBar } from "@/components/desk";
import {
  conversationDetail,
  clearMessage,
  rateMessage,
  getSpeech,
} from "@/api/chat";
const router = useRouter();
let imgUrl = ref(process.env.VUE_APP_BASE_URI + "/media/");
let conversationObj = ref({
  conversationName: "",
});
let uploadParams = ref({});
let promptParams = ref({});
let mianscroll = ref(null);
let messageArr = ref([]);
const icons = ref([]);

let contextScroll = ref(null);

// onMounted(() => {
//   Prism.highlightAll(); // 全局代码高亮
// });

watch(
  () => router.currentRoute.value,
  (newValue) => {
    if (newValue.query.id != undefined) {
      getList(newValue.query.id);
      // 指引方法
      getSpeechList();
    }
  },
  { immediate: true }
);
// 话术接口
let speechArray = ref([]);
async function getSpeechList() {
  let res = await getSpeech();
  speechArray.value = res.data.data;
}
// 重新生成
function speechClick(item) {
  if (sseState.value) return;
  sendMsg(item);
}
// 会话列表
function getList(id) {
  conversationDetail({ conversationId: id }).then((res) => {
    if (res.data.code == 0) {
      conversationObj.value = res.data.data;
      messageArr.value = [];
      conversationObj.value.message.forEach((self, index) => {
        if (
          index > 1 &&
          self.create_time -
            conversationObj.value.message[index - 1].create_time >
            300
        ) {
          messageArr.value.push({
            time: formatTime("YYYY年mm月dd日 HH:MM:SS", self.create_time),
          });
        }
        messageArr.value.push({
          ...self,
          type: self.context.substring(0, 4) === "http",
          fileName:
            self.context.split("pdf_download/")[1] ||
            self.context.split("pdf_upload/")[1],
          // context:
          //   self.context.substring(0, 4) === "http"
          //     ? self.context
          //     : Prism.highlight(
          //         self.context,
          //         Prism.languages.javascript,
          //         "javascript"
          //       ),
          context:
            self.context.substring(0, 4) === "http"
              ? self.context
              : marked(self.context),
        });
      });
      // marked(self.context);
      // console.log(messageArr.value, "conversationObj.value.message");
      uploadParams.value = {
        knowledgeID: res.data.data.conversationBase,
      };
      promptParams.value = {
        temperature: res.data.data.temperature,
        promptId: res.data.data.promptId,
        promptTitle: res.data.data.promptTitle,
      };
      icons.value = Object.values(res.data.data.icon_dict);
      nextTick(() => {
        mianscroll.value.scrollTo({
          top: mianscroll.value.scrollHeight,
          behavior: "smooth",
        });
      });
    }
  });
}
// 时间处理
const formatTime = (format = "", num = new Date().getTime()) => {
  format = format || "YYYY-mm-dd HH:MM:SS"; //第一个参数不填时，使用默认格式
  let ret, date, renum;
  // 处理时间戳，js一般获取的时间戳是13位，PHP一般是10位,根据实际情况做判断处理
  if (num.toString().length == 10) {
    date = new Date(parseInt(num) * 1000);
  } else {
    date = new Date(parseInt(num));
  }
  const opt = {
    Y: date.getFullYear().toString(), // 年
    m: (date.getMonth() + 1).toString(), // 月
    d: date.getDate().toString(), // 日
    H: date.getHours().toString(), // 时
    M: date.getMinutes().toString(), // 分
    S: date.getSeconds().toString(), // 秒
    // 目前用的是这六种符号,有其他格式化字符需求可以继续添加，值必须转化成字符串
  };
  for (var k in opt) {
    ret = new RegExp("(" + k + "+)").exec(format);
    if (ret) {
      renum = ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0"); //根据复数前面是否补零,如“mm”补零，单“m”前面不补零
      format = format.replace(ret[1], renum); //替换
    }
  }
  return format;
};
// 复制回答
function handleEvaluate(id, type) {
  let form = {};
  if (type == "copy") {
    const textarea = document.createElement("textarea");
    textarea.value = id;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    return;
  }
  if (type == "praise") {
    form = {
      MessageId: id,
      praise: 1,
      trample: 0,
    };
  }
  if (type == "trample") {
    form = {
      MessageId: id,
      praise: 0,
      trample: 1,
    };
  }
  rateMessage(form).then((res) => {
    if (res.data.code == 0) {
      if (type == "praise") {
        messageArr.value.find((curr) => curr.id === id).praise = true;
        messageArr.value.find((curr) => curr.id === id).trample = false;
      }
      if (type == "trample") {
        messageArr.value.find((curr) => curr.id === id).trample = true;
        messageArr.value.find((curr) => curr.id === id).praise = false;
      }
    }
  });
}
// 发送信息（sse请求）
import { EventSourcePolyfill } from "event-source-polyfill";
// 发送信息（sse）
let typeState = ref(false);
let sseState = ref(false);
let isRequest = ref(false);
const messageQueue = ref("");
// sse请求
// function seeRequest(e) {
//   const headers = {
//     "Content-Type": "text/event-stream",
//     "Cache-Control": "no-cache",
//     Connection: "keep-alive",
//     Authorization: "Bearer" + getToken(),
//   };
//   const es = new EventSourcePolyfill(
//     process.env.VUE_APP_BASE_URI +
//       process.env.VUE_APP_BASE_API +
//       `/chat/messageStream?conversationId=${conversationObj.value.conversationId}&content=${e}`,
//     {
//       headers,
//     }
//   );
//   // 请求中返回的数据
//   es.onmessage = function (event) {
//     // 数据处理
//     let data = messageArr.value[messageArr.value.length - 1];
//     // 返回done请求结束
//     if (event.data == "[DONE]") {
//       isRequest.value = false;
//       messageQueue.value = [];
//       data.isGenerate = false;
//       sseState.value = false;
//       return es.close();
//     }
//     let res = JSON.parse(event.data);
//     // 第一条数据
//     if (!sseState.value) {
//       sseState.value = true;
//       messageArr.value.pop();
//       messageArr.value.push({
//         id: res.message_id,
//         context: res.content,
//         robot_id: res.from,
//         user_id: null,
//         resource: res.source,
//         create_time: res.time,
//         praise: false,
//         trample: false,
//         isGenerate: true,
//       });
//       nextTick(() => {
//         mianscroll.value.scrollTo({
//           top: mianscroll.value.scrollHeight,
//           behavior: "smooth",
//         });
//       });
//     }
//     // console.log(res.content, "res.content");
//     if (res.content.includes("```")) {
//       typeState.value = !typeState.value;
//     }
//     messageQueue.value += res.content;
//     if (typeState.value) {
//       data.context = marked(messageQueue.value + "```");
//       // data.context = messageQueue.value;
//       typeState.value = false;
//       // Prism.highlightAll();
//       // data.context = Prism.highlight(
//       //   messageQueue.value + "```",
//       //   Prism.languages.javascript,
//       //   "javascript"
//       // );
//       // Prism.highlightAll();
//     } else {
//       // data.context = messageQueue.value;
//       // data.context = Prism.highlight(
//       //   messageQueue.value,
//       //   Prism.languages.javascript,
//       //   "javascript"
//       // );
//       data.context = marked(messageQueue.value);
//       // Prism.highlightAll();
//     }
//     nextTick(() => {
//       mianscroll.value.scrollTo({
//         top: mianscroll.value.scrollHeight,
//         behavior: "smooth",
//       });
//     });
//   };
//   es.onerror = (error) => {
//     isRequest.value = false;
//     messageArr.value.pop();
//     messageArr.value.push({
//       id: "",
//       context: "服务器繁忙，请稍后再试",
//       robot_id: 1,
//       user_id: null,
//       resource: "",
//       create_time: "",
//       praise: false,
//       trample: false,
//     });
//     return es.close();
//   };
// }

// sse请求（POST）
import { fetchEventSource } from "@microsoft/fetch-event-source";
function seeRequest(e) {
  const ctrl = new AbortController();
  const params = new URLSearchParams({
    content: e,
    conversationId: conversationObj.value.conversationId,
    // outputMode: 1,
  });
  fetchEventSource(
    process.env.VUE_APP_BASE_URI +
      process.env.VUE_APP_BASE_API +
      `/chat/messageStream`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        // "Content-Type": "text/event-stream",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        Authorization: "Bearer" + getToken(),
      },
      body: params,
      signal: ctrl.signal,
      onmessage(event) {
        // 数据处理
        let data = messageArr.value[messageArr.value.length - 1];
        // 返回done请求结束
        if (event.data == "[DONE]") {
          isRequest.value = false;
          messageQueue.value = [];
          data.isGenerate = false;
          sseState.value = false;
          return;
        }
        let res = JSON.parse(event.data);
        // 第一条数据
        if (!sseState.value) {
          sseState.value = true;
          messageArr.value.pop();
          messageArr.value.push({
            id: res.message_id,
            context: res.content,
            robot_id: res.from,
            user_id: null,
            resource: res.source,
            create_time: res.time,
            praise: false,
            trample: false,
            isGenerate: true,
          });
          nextTick(() => {
            mianscroll.value.scrollTo({
              top: mianscroll.value.scrollHeight,
              behavior: "smooth",
            });
          });
        }
        if (res.content.includes("```")) {
          typeState.value = !typeState.value;
        }
        messageQueue.value += res.content;
        if (typeState.value) {
          data.context = marked(messageQueue.value + "```");
          typeState.value = false;
        } else {
          data.context = marked(messageQueue.value);
        }
        data.id = res.message_id;
        nextTick(() => {
          mianscroll.value.scrollTo({
            top: mianscroll.value.scrollHeight,
            behavior: "smooth",
          });
        });
      },
      onerror(err) {
        isRequest.value = false;
        messageArr.value.pop();
        messageArr.value.push({
          id: "",
          context: "服务器繁忙，请稍后再试",
          robot_id: 1,
          user_id: null,
          resource: "",
          create_time: "",
          praise: false,
          trample: false,
        });
        throw err;
      },
    }
  );
}
function sendMsg(e) {
  isRequest.value = true;
  // 新增自己发送的内容
  e &&
    messageArr.value.push({
      id: null,
      context: e,
      robot_id: null,
      user_id: 100000,
      resource: null,
      create_time: 1685928346,
      praise: false,
      trample: false,
    });
  messageArr.value.push({
    loading: true,
  });
  // 保持在底部
  nextTick(() => {
    mianscroll.value.scrollTo({
      top: mianscroll.value.scrollHeight,
      behavior: "smooth",
    });
  });
  seeRequest(e);
}

// 清空聊天记录
function handleClear() {
  clearMessage({ conversationId: router.currentRoute.value.query.id }).then(
    (res) => {
      if (res.data.code == 0) {
        messageArr.value = [];
      }
    }
  );
}
</script>
<style scoped lang="scss">
.top-bar {
  background: rgba(0, 0, 0, 0.04);
  border-bottom: rgba(0, 0, 0, 0.1);
  height: 60px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
}

.top-bar .name {
  flex: 1;
  text-align: left;
  font-size: 18px;
  margin-left: 10px;
}
.top-bar .clear {
  color: #ff2840;
  font-size: 14px;
  cursor: pointer;
}

// .messages::-webkit-scrollbar {
//   width: 10px;
// }
.messages {
  // height: calc(100% - 220px);
  // height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  .guide-box {
    background: #fff;
    width: 70%;
    margin: 0 auto;
    border-radius: 10px;
    padding: 10px;
    margin-top: 30px;
    font-size: 14px;
    .title {
      font-size: 36px;
    }
    .text {
      display: inline-block;
      line-height: 24px;
      font-size: 14px;
      margin: 8px 0;
    }
    .tab-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:nth-child(2) {
        cursor: pointer;
        color: #4545f3;
        margin-right: 10px;
        i {
          margin-right: 5px;
        }
      }
    }
    .guide-tab {
      margin-top: 10px;
      display: flex;
      // justify-content: space-between;
      justify-content: flex-start;
      flex-wrap: wrap;
      .tab-box {
        flex: 1;
        min-width: calc((100% - 10px) / 2);
        background: #f5f7fe;
        padding: 10px;
        border-radius: 10px;
        font-size: 12px;
        margin-bottom: 10px;
        cursor: pointer;
        div:nth-child(odd) {
          font-weight: bolder;
          font-size: 18px;
          line-height: 30px;
        }
      }
      .tab-box:nth-child(odd) {
        margin-right: 10px;
      }
    }
  }
}
.messages .date-split {
  text-align: center;
  color: #afafaf;
  display: flex;
  justify-content: center;
  align-items: center;
}
.messages .date-split:before,
.messages .date-split:after {
  content: "";
  display: block;
  height: 1px;
  width: 100px;
  background-color: #c4c3c3;
}
.messages .date-split:before {
  margin-right: 20px;
}
.messages .date-split:after {
  margin-left: 20px;
}

.messages .message.fromme,
.messages .message.fromrobot {
  display: flex;
  margin: 20px;
  position: relative;
}

.messages .message.fromme:after,
.messages .message.fromrobot:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 10px 0px;
  border-color: transparent #ffffff transparent transparent;
  position: absolute;
  left: 60px;
  top: 14px;
}

.messages .message .content {
  max-width: 70%;
  width: fit-content;
  // min-height: 78.5px;
  background-color: #fff;
  padding: 10px 15px 10px;
  border-radius: 10px 10px 10px 10px;
  margin-left: 30px;
  color: #626c76;
  position: relative;
  /* min-width: 320px; */
  text-align: left;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .user-text {
      color: #fff;
    }
    img {
      background: #fff;
      padding: 10px;
      border-radius: 5px;
    }
    div {
      margin-top: 8px;
      color: #000;
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.messages .message .content .source {
  // position: absolute;
  bottom: 0;
  left: 3%;
  // width: 94%;
  height: 36px;
  line-height: 36px;
  background-color: #ffffff;
  color: #297fff;
  border-radius: 0 0px 10px 10px;
  font-size: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.04);
  display: flex;
  overflow: hidden;
}
.messages .message .content .source span {
  flex: 1;
  text-align: left;
}
.messages .message .content .source .evalution {
  color: #b5b5b5;
  // width: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .refresh {
    color: #297fff;
    cursor: pointer;
    font-size: 14px;
  }
}
.messages .message .content .source .evalution .left i {
  cursor: pointer;
  margin-right: 10px;
}
.messages .message .content .source .evalution .left i.active {
  color: #297fff;
}
.messages .message.fromme {
  flex-direction: row-reverse;
  padding-bottom: 0px;
}
.messages .message.fromme:after {
  border-color: #437df7 transparent transparent transparent;
  right: 52px;
  left: unset;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #437df7;
}
.messages .message.fromme .content {
  border-radius: 10px 10px 10px 10px;
  margin-left: unset;
  margin-right: 30px;
  background-color: #437df7;
  color: #fff;
  padding-bottom: 15px;
  text-align: left;
}
.messages .message.time {
  margin: 10px 0px;
}
.messages .message .send-time {
  text-align: center;
  color: rgba(0, 0, 0, 0.4);
}

.loading {
  margin: 10px auto;
  width: 150px;
}
.loading > div {
  width: 20px;
  height: 20px;
  background-color: #999;
  border-radius: 50%;
  display: inline-block;
  animation: action 1.5s infinite ease-in-out;
  animation-fill-mode: both;
}
.loading .point1 {
  animation-delay: -0.3s;
}
.loading .point2 {
  animation-delay: -0.1s;
}

@keyframes action {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

::v-deep .hljs {
  border-radius: 10px !important;
  margin: 10px 0;
}
</style>
