<template>
  <div
    class="chat-container"
    v-if="!deviceMobile"
    :class="{ desk: !deviceMobile }"
  >
    <div class="menu-aside">
      <logo></logo>
      <chat-menu></chat-menu>
      <div class="fix-sidebottom">
        <!-- <setting></setting> -->
        <user-manage></user-manage>
      </div>
    </div>
    <conversation-list></conversation-list>
  </div>
  <div
    class="chat-container"
    v-if="deviceMobile"
    :class="{ mobile: deviceMobile }"
  >
    <mobile-conversation-list></mobile-conversation-list>
    <mobile-footer-nav></mobile-footer-nav>
  </div>
</template>

<script setup>
import { watch, onMounted, ref } from "vue";
import {
  Logo,
  ChatMenu,
  UserManage,
  ConversationList,
  Setting,
} from "@/components/desk";
import { MobileConversationList, MobileFooterNav } from "@/components/mobile";
import { isMobile } from "@/utils/chat";
import { useRoute } from "vue-router";

let deviceMobile = ref(isMobile());

onMounted(() => {
  history.replaceState(null, null, window.location.pathname);
});

const route = useRoute();

watch(
  () => route,
  (newVal) => {
    if (deviceMobile.value && newVal.query.isFirst == "true") {
      // 如果是移动端，并且是第一次进入，则跳转到聊天列表
      localStorage.setItem("isFirst", "true");
    }
  },
  { immediate: true }
);
</script>
<style lang="scss" scoped>
.chat-container .menu-aside {
  width: 62px;
  height: 100%;
  background: #e0e0e1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.fix-sidebottom {
  position: absolute;
  bottom: 30px;
  left: 0px;
  width: 100%;
  cursor: pointer;
}
.van-search {
  width: 100%;
}

::v-deep .avatar-uploader .el-upload {
  display: block;
}
::v-deep .file-name img {
  width: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
::v-deep .file-name a {
  color: #000000;
  text-decoration: none;
}
::v-deep .file-name a:hover {
  text-decoration: underline;
}
::v-deep .el-upload-list__item.is-success {
  display: none;
}
.form-item {
  margin-bottom: 20px;
  text-align: left;
}
.form-item .title {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
}
.el-checkbox-group {
  text-align: left;
}
.el-checkbox-width {
  width: 130px;
  height: 25px;
  margin-bottom: 10px;
}
.dialog-footer {
  text-align: right;
}
</style>
