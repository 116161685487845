<template>
  <div class="bottom-bar">
    <div class="msg-params">
      <div>
        <el-dropdown class="param-left" @command="handlePrompt">
          <span style="line-height: 24px">
            {{ promptParams.promptTitle || "问答"
            }}<i class="iconfont">&#xe61c;</i>
          </span>

          <template v-slot:dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                :command="item"
                :class="{ selected: props.promptParams.promptId == item.id }"
                v-for="item in promptArr"
                :key="item.id"
                >{{ item.title }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
        <!-- <el-button
          @click="fileClick"
          class="left-but"
          type="primary"
          size="small"
        >
          文档翻译<el-icon class="el-icon--right"><Upload /></el-icon>
        </el-button> -->
      </div>
      <el-dropdown class="param-right" @command="handleTemperature">
        <span>
          <i class="iconfont">&#xe61d;</i>
        </span>
        <template v-slot:dropdown>
          <el-dropdown-menu>
            <el-dropdown-item
              :command="item"
              :class="{
                selected: props.promptParams.temperature == item.temperature,
              }"
              v-for="item in temperatureArr"
              :key="item.temperature"
              >{{ item.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
    <div class="msg-send">
      <div class="msg-box">
        <!-- + isMacTips -->
        <!-- :placeholder="'请输入内容...(Shift+Enter发送)'" -->
        <el-input
          @keydown.enter.native="keyDown"
          type="textarea"
          class="msg-input"
          :autosize="{ minRows: 2, maxRows: 5 }"
          :class="{ focus: isFocus }"
          :placeholder="'请输入内容...'"
          :show-word-limit="showLimit"
          maxlength="2000"
          @focus="handleFocus"
          @blur="handleBlur"
          @input="handleInput"
          v-model="messageValue"
        >
        </el-input>
        <!-- <textarea  class="msg-input" v-model="messageValue" :class="{'focus':isFocus}"  placeholder="输入聊天内容..."></textarea> -->
        <el-button type="primary" title="发送" @click="sendMsg">
          <i class="iconfont">&#xe652;</i></el-button
        >
      </div>
      <div class="upload" v-if="props.uploadParams.knowledgeID != undefined">
        <el-upload
          :action="uploadUrl"
          :data="props.uploadParams"
          :headers="headers"
        >
          <i class="iconfont">&#xe60a;</i>
        </el-upload>
      </div>
    </div>
    <el-dialog v-model="dialogFormVisible" title="PDF翻译" height>
      <el-upload
        class="upload-demo"
        v-if="!isSuccessFile"
        :action="uploadPdfUrl"
        :headers="headers"
        :before-upload="beforeUpload"
        :on-success="onSuccess"
        :limit="1"
        drag
        :data="{ conversationId: id }"
        accept=".pdf"
      >
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">请上传您要翻译的pdf文件</div>
        <template #tip>
          <div class="el-upload__tip">大小小于100M的pdf文件</div>
        </template>
      </el-upload>
      <div v-if="isSuccessFile" class="pdf-a-box">
        <a class="pdf-a" :href="pdfFile.content" download>
          <div class="box">
            <img src="@/assets/img/icon08.png" />
            <div>{{ pdfFile.fileName || "翻译文件" }}</div>
          </div>
        </a>
        <div class="del-icon" @click.stop="isSuccessFile = false">
          <el-icon><CloseBold /></el-icon>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, onMounted, onUnmounted, defineEmits } from "vue";
import { getToken, getTemperature } from "@/utils/auth";
import { getPrompts, sendMessage, setPrompt } from "@/api/chat";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
const props = defineProps({
  uploadParams: {
    type: Object,
  },
  promptParams: {
    type: Object,
  },
  isRequest: {
    type: Boolean,
  },
});

const router = useRouter();
let isFocus = ref(false);
const emit = defineEmits(["sendValue"]);
const uploadUrl = process.env.VUE_APP_BASE_URI + "/store/uploadKnowledgeFile";
let headers = {
  Authorization: "Bearer" + getToken(),
  ContentType:
    "multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq",
};
let messageValue = ref("");
let promptArr = ref([]);
let temperatureArr = ref([]);
let dialogFormVisible = ref(false);

import { UploadFilled } from "@element-plus/icons-vue";
const uploadPdfUrl =
  process.env.VUE_APP_BASE_URI +
  process.env.VUE_APP_BASE_API +
  "/chat/pdfConverter";

// let isMacTips = ref("");
let id = ref("");
onMounted(() => {
  // var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
  // isMacTips.value = isMac ? "(Shift+Enter发送)" : "(Shift+Enter发送)";
  temperatureArr.value = JSON.parse(getTemperature());
  window.addEventListener("keydown", keyDown);
  id.value = router.currentRoute.value.query.id;
  getPromptList();
});
onUnmounted(() => {
  window.removeEventListener("keydown", keyDown, false);
});
function keyDown(e) {
  if (!e.shiftKey && e.keyCode == 13) {
    e.cancelBubble = true; //ie阻止冒泡行为
    e.stopPropagation(); //Firefox阻止冒泡行为
    e.preventDefault(); //取消事件的默认动作*换行
    //以下处理发送消息代码
    sendMsg();
  }
  // if (e.ctrlKey && e.keyCode == 13) {
  //   //用户点击了ctrl+enter触发
  //   sendMsg();
  // }
}
function handlePrompt(command) {
  props.promptParams.promptId = command.id;
  props.promptParams.promptTitle = command.title;
  let form = {
    conversationId: router.currentRoute.value.query.id,
    promptId: command.id,
  };
  setPrompt(router.currentRoute.value.query.id, form).then((res) => {
    if (res.data.code == 0) {
    }
  });
}
function handleTemperature(command) {
  props.promptParams.temperature = command.temperature;
  let form = {
    conversationId: router.currentRoute.value.query.id,
    temperature: command.temperature,
  };
  setPrompt(router.currentRoute.value.query.id, form).then((res) => {
    if (res.data.code == 0) {
    }
  });
}

function getPromptList() {
  getPrompts({ conversationId: router.currentRoute.value.query.id }).then(
    (res) => {
      promptArr.value = res.data.data.rows;
    }
  );
}

function handleFocus() {
  isFocus.value = true;
}
function handleBlur() {
  isFocus.value = false;
}
let showLimit = ref(false);
function handleInput(e) {
  if (e.length >= 2000) {
    showLimit.value = true;
  } else {
    showLimit.value = false;
  }
}

// function getTemperatureList() {
//   getTemperature().then((res) => {
//     temperatureArr.value =res.data.data
//   })
// }
function sendMsg() {
  if (props.isRequest) {
    ElMessage.error("请等机器人回复后再发送哦～");
    return;
  }
  const textWithoutNewlines = messageValue.value.replace(/[\r\n]+/g, "");
  if (textWithoutNewlines.trim() === "") {
    return;
  }
  showLimit.value = false;
  emit("sendValue", messageValue.value);
  messageValue.value = "";
}
// PDF翻译
function fileClick() {
  if (props.isRequest) {
    ElMessage.error("请等机器人回复后再发送哦～");
    return;
  }
  dialogFormVisible.value = true;
}
// 判断大小格式
function beforeUpload(file) {
  const isLarger = file.size > 100 * 1024 * 1024; // 检查文件大小是否超过100MB
  const isPdf = file.type === "application/pdf"; // 检查文件类型是否为PDF
  if (isLarger || !isPdf) {
    this.$message.error("文件大小不能超过100MB，且必须为PDF格式"); // 如果超过或不是PDF格式，显示错误信息
    return false; // 阻止上传
  }
  return true; // 如果文件大小和类型都正常，继续上传
}
// 成功后回调
let isSuccessFile = ref(false);
let pdfFile = ref("");
function onSuccess(UploadFile) {
  console.log(UploadFile, "UploadFile");
  isSuccessFile.value = true;
  pdfFile.value = UploadFile.data;
  emit("listValue", router.currentRoute.value.query.id);
}
</script>
<style scoped lang="scss">
::v-deep .el-dropdown-menu__item.selected {
  color: #297fff;
}
.pdf-a-box {
  position: relative;
  overflow: hidden;
  .pdf-a {
    z-index: 9;
    display: inline-block;
    width: 100%;
    height: 185px;
    padding: 40px 10px;
    background-color: #ffffff;
    border: 1px dashed #dcdfe6;
    border-radius: 6px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    color: #606266;
    display: flex;
    justify-content: center;
    .box {
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-self: center;
      align-items: center;
      div {
        margin-top: 15px;
      }
    }
  }
  .del-icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
    padding: 10px;
    z-index: 99;
    cursor: pointer;
  }
}
.bottom-bar {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 10px 40px;
}
.msg-params {
  display: flex;
  height: 24px;
  /* line-height: 24px; */
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  .left-but {
    margin-left: 10px;
  }
}
.msg-params .param-left {
  cursor: pointer;
}
.msg-params .param-left i {
  font-size: 12px;
  color: #909090;
  margin-left: 5px;
}
.msg-params .param-left span {
  color: #297fff;
}
.msg-params .param-right {
  // margin-right: 80px;
  margin-right: 30px;
}
.msg-params .param-right i {
  font-size: 14px;
  font-weight: bold;
  color: #909090;
  cursor: pointer;
}

.msg-send {
  display: flex;
  // align-items: end;
}
.msg-box {
  flex: 1;
  background: #ffffff;
  padding: 8px 8px;
  border-radius: 8px;
  position: relative;
}
.msg-box .textarea {
  height: 64px;
}
.msg-box .el-button {
  padding: 8px;
  position: absolute;
  right: 8px;
  bottom: 8px;
}

.upload {
  margin-left: 20px;
}
.upload i {
  font-size: 30px;
  color: #09b0c5;
}
::v-deep .el-textarea {
  width: calc(100% - 40px);
  // min-height: 32px;
  // max-height: 60px;
  line-height: 32px;
  overflow: hidden;
  background-color: #fff;
  font-size: 18px;
  border: none;
  // display: flex;
  vertical-align: middle;
  transition: height 0.3s ease-in-out;
  .el-input__count {
    position: static;
    padding-left: 11px;
  }
}
/* .el-textarea {
  max-height: 0px;
} */
::v-deep .el-textarea__inner {
  box-shadow: none;
}
// ::v-deep .el-textarea.focus {
//   height: 64px;
// }
// ::v-deep .el-textarea:hover .el-textarea__inner {
//   box-shadow: none;
//   /* line-height: 1.2; */
// }
::v-deep .el-textarea::placeholder {
  color: #c5c5c5;
}
</style>
