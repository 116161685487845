<template>
  <div class="menu-user" title="用户管理" @click="userClick" >
    <img  src="@/assets/img/user.jpg" />
  </div>
  <div class="user-manage" :class="{'show':show}">
    <div class="item" @click="handlePwd">
        修改密码
      </div>
      <div class="item" @click="handleLogout">
        退出登录 
      </div>
  </div>
  <el-dialog
  title="修改密码"
  v-model="dialogVisible"
  width="30%"
  :before-close="handleClose">
  <el-form ref="pwdRef" :model="form" :rules="rules" label-width="100px">
  <el-form-item label="原密码"  prop="OldPassWord">
    <el-input v-model="form.OldPassWord" show-password></el-input>
  </el-form-item>
  <el-form-item label="新密码" prop="newPassWord">
    <el-input v-model="form.newPassWord" show-password></el-input>
  </el-form-item>
  <el-form-item label="确定密码" prop="surepwd">
    <el-input v-model="form.surepwd" show-password></el-input>
  </el-form-item>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="onSubmit(pwdRef)">确 定</el-button>
  </div>
</el-dialog>
</template>
<script setup>
import { logout,updatePwd }  from "@/api/chat";
import { useRouter } from 'vue-router'
import { removeToken,removeUser,removeFileFormat,removeTemperature } from '@/utils/auth'
import { ref,reactive,onMounted,onUnmounted  } from "vue";
import { ElMessage } from "element-plus";
const router=useRouter();
const show = ref(false);
const dialogVisible =ref(false)
const surepwdRule= (rule, value, callback) => {
  if(form.newPassWord != form.surepwd ){
    callback(new Error('确认密码有误'))
  }else{
    callback()
  }
}
const form = reactive({
  OldPassWord: "",
  newPassWord: "",
  surepwd: "",
});
const rules = reactive({
  OldPassWord: [{ required: true, trigger: "blur", message: "请输入您的原密码" }],
  newPassWord: [{ required: true, trigger: "blur", message: "请输入您的新密码" }],
  surepwd: [
    { required: true, trigger: "blur", message: "请输入您的密码" },
    { validator: surepwdRule, trigger: 'blur' } 
  ],
});

const keyDown = (e) => {
  //如果是回车则执行登录方法
  if (e.keyCode == 13) {
    onSubmit(pwdRef.value)
  }
}
onMounted(() => {
  window.addEventListener('keydown', keyDown)
})
onUnmounted(() => {
  window.removeEventListener('keydown', keyDown, false)
})
const pwdRef = ref()
function handlePwd(){
  dialogVisible.value=true
  show.value=false
}
function userClick(){
      show.value=!show.value
    }
function handleClose(){
  dialogVisible.value=false
}

const onSubmit = async (formEl) => {
      if (!formEl) return;
      await formEl.validate((valid) => {
        if (valid) {
          updatePwd(form).then((res) => {  
            if(res.data.code==0){
            dialogVisible.value=false
            ElMessage.success('密码更新成功');
            }
          })
        } 
      });
    };

//  function onsubmit(formEl){
//    formEl.validate((valid) => {
//     if (!valid) return
//     updatePwd(form.value).then((res) => {  
//     if(res.data.code==0){
//     dialogVisible.value=false
//     }
//   })
//   })
// }
function handleLogout(){
  logout().then((res) => {  
    if(res.data.code==0){
      removeToken()
      removeUser()
      removeFileFormat()
      removeTemperature()
      router.push({
          path:'/login',
        })
    }
  })
}
</script>
<style scoped>
.menu-user{
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  margin: 10px auto 0px;
  cursor: pointer;
}
.menu-user img{
  width: 100%;
}
.user-manage{
  position: absolute;
  bottom: -20px;
  right: -92px;
  width: 92px;
  line-height: 36px;
  background-color: #363e47;
  text-align: left;
  z-index: 2;
  color: #fff;
  display: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  text-align: center;
}
.user-manage.show{
  display: block;
}
.user-manage .item{
  color: #8c8c8c;
}
.user-manage .item a{
  color: #8c8c8c;
  display: block;
  font-size: 14px;
  text-decoration: none;
}
.user-manage .item:hover {
  background-color: #2f3033;
}
</style>