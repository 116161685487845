<template>
  <van-tabbar
    active-color="#297FFF"
    inactive-color="#666666"
    v-model="tabbarTempValue"
    route
  >
    <van-tabbar-item name="news" replace to="/conversation">
      <div class="tabbar-icon">
        <i class="iconfont">&#xe617;</i>
      </div>
      会话
    </van-tabbar-item>
    <!-- <van-tabbar-item name="workBench" replace to="/knowledge">
      <div class="tabbar-icon">
        <i class="iconfont">&#xe61a;</i>
      </div>
      知识库
    </van-tabbar-item> -->
    <van-tabbar-item name="setting" replace to="/person">
      <div class="tabbar-icon">
        <i class="iconfont">&#xe65a;</i>
      </div>
      个人中心
    </van-tabbar-item>
  </van-tabbar>
</template>
<script setup>
import { ref } from "vue";
let tabbarTempValue = ref("");
</script>
<style scoped>
.tabbar-icon {
  margin-bottom: 4px;
}
.tabbar-icon i {
  font-size: 18px;
}

:deep(.van-tabbar-item__text) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>
