export default {
    '-1': '操作失败',
    '1001': '参数不足',
    '1002': '无效参数',
    '1100': '未登录',
    '1101': '无效TOKEN',
    '1102': 'TOKEN过期',
    '1201': '用户不存在',
    '1202': '密码错误',
    '1300': '知识库不存在',
    '1301': '文件类型错误',
    '1302': '文件正在学习中',
    '1303': '文件不存在',
    '1304': '知识库重构中',
    '1305': '知识库是空的',
    '1400': 'AI服务器连接异常',
    '1401': 'AI服务器维护中',
    '1500': '会话不存在',
    '1501': '不允许发送空的内容',
    '1502': '机器人不存在',
    '1503': '消息写入异常',
    '1600': '系统服务错误',
    '1706': '文件名重复'
}
