<template>
  <div>
    <div class="biggestBox">
      <ul>
        <!-- data-type=0 隐藏删除按钮 data-type=1 显示删除按钮 -->
        <li
          class="li_vessel"
          v-for="(item, index) in lists"
          data-type="0"
          :key="index"
        >
          <!-- "touchstart" 当手指触摸屏幕时候触发  "touchend"  当手指从屏幕上离开的时候触发  "capture" 用于事件捕获-->
          <div
            @touchstart.capture="touchStart"
            @touchend.capture="touchEnd"
            @click="oneself(item.id)"
          >
            <div class="contant">
              <div class="image">
                <img
                  :class="{ small: item.icons.length > 1 }"
                  :src="child ? imgUrl + child : ''"
                  v-for="(child, index) in item.icons"
                  :key="index"
                />
              </div>
              <div class="rightBox">
                <div>{{ item.name }}</div>
                <div>{{ item.content }}</div>
              </div>
            </div>
          </div>
          <div
            class="removeBtn"
            @click="remove($event, item.id)"
            :data-index="index"
          >
            删除
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { deleteConversation } from "@/api/chat";
import { ElMessage } from "element-plus";
export default {
  name: "index",
  props: {
    lists: {
      //消息列表
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      startX: 0, //滑动开始
      endX: 0, //滑动结束
      imgUrl: process.env.VUE_APP_BASE_URI + "/media/",
    };
  },
  methods: {
    // 向左滑动出现删除按钮时，点击商品信息区域取消删除
    oneself(id) {
      if (this.checkSlide()) {
        this.restSlide();
      } else {
        // 点击商品信息弹出弹框
        this.$router.push({ path: "/conversation/detail", query: { id: id } });
      }
    },
    //滑动开始
    touchStart(e) {
      // 记录初始位置
      this.startX = e.touches[0].clientX;
    },
    //滑动结束
    touchEnd(e) {
      // 当前滑动的父级元素
      let parentElement = e.currentTarget.parentElement;
      // 记录结束位置
      this.endX = e.changedTouches[0].clientX;
      // 左滑大于30距离删除出现
      if (parentElement.dataset.type == 0 && this.startX - this.endX > 30) {
        this.restSlide();
        parentElement.dataset.type = 1;
      }
      // 右滑
      if (parentElement.dataset.type == 1 && this.startX - this.endX < -30) {
        this.restSlide();
        parentElement.dataset.type = 0;
      }
      this.startX = 0;
      this.endX = 0;
    },
    //判断当前是否有滑块处于滑动状态
    checkSlide() {
      let listItems = document.querySelectorAll(".li_vessel");
      for (let i = 0; i < listItems.length; i++) {
        if (listItems[i].dataset.type == 1) {
          return true;
        }
      }
      return false;
    },
    //复位滑动状态
    restSlide() {
      let listItems = document.querySelectorAll(".li_vessel");
      // 复位
      for (let i = 0; i < listItems.length; i++) {
        listItems[i].dataset.type = 0;
      }
    },
    //删除数据信息
    remove(e, id) {
      deleteConversation(id).then((res) => {
        if (res.data.code == 0) {
          ElMessage.success("删除成功");
        }
      });
      // 当前索引值
      let index = e.currentTarget.dataset.index;
      // 复位
      this.restSlide();
      // 删除数组lists中一个数据
      this.lists.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.biggestBox {
  overflow: hidden;
  /*超出部分隐藏*/
}

ul {
  /* 消除 ul 默认样式 */
  list-style: none;
  padding: 0;
  margin: 0;
}

.li_vessel {
  /* 全部样式 0.2秒 缓动*/
  transition: all 0.2s;
}

/* =0隐藏 */
.li_vessel[data-type="0"] {
  transform: translate3d(0, 0, 0);
}

/* =1显示 */
.li_vessel[data-type="1"] {
  /* -64px 设置的越大可以左滑的距离越远，最好与下面删除按钮的宽度以及定位的距离设置一样的值*/
  transform: translate3d(-64px, 0, 0);
}

/* 删除按钮 */
.li_vessel .removeBtn {
  width: 64px;
  height: 100%;
  background: #ff4949;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 22px;
  position: absolute;
  top: 0px;
  right: -64px;
  line-height: 70px;
  text-align: center;
  border-radius: 2px;
}

/* 左边的图片样式 */
.contant {
  overflow: hidden;
  /*消除图片带来的浮动*/
  padding: 12px 16px 0px;
  background: #ffffff;
}

.contant .image {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  float: left;
  overflow: hidden;
  background: #dedede;
  display: flex;
  align-items: center;
}
.contant .image img {
  border-radius: 8px;
  width: 40px;
  height: 40px;
}
.contant img.small {
  width: 19px;
  height: 19px;
  border-radius: 4px;
}

/* 右边的文字信息样式 */
.rightBox {
  overflow: hidden;
  padding-left: 8px;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 12px;
}

.rightBox div:first-child {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  margin-bottom: 5px;
}

.rightBox div:last-child {
  height: 19px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
