import axios from 'axios'
import qs from 'qs';
import { getToken } from '@/utils/auth'
import { tansParams } from "@/utils/chat";
import { ElMessage } from "element-plus";
import errorCode from '@/utils/errorCode'
import { removeToken, removeUser, removeFileFormat, removeTemperature } from '@/utils/auth'

// 创建axios实例
// const streamService = axios.create({
//   // axios中请求配置有baseURL选项，表示请求URL公共部分
//   baseURL: process.env.VUE_APP_BASE_API,
//   withCredentials: true,
//   headers: {
//     'Content-Type': 'application/x-www-form-urlencoded',
//     'Accept': '*/*',
//     responseType: 'stream'
//   },
//   // 超时
//   timeout: 60000,
// })

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': '*/*'
  },
  // 超时
  timeout: 60000,
})

service.interceptors.request.use(
  config => {
    // 将请求数据转换成功 formdata 接收格式
    config.data = qs.stringify(config.data)
    // return config
    return config;
  },
  error => {
    return Promise.reject();
  }
);

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = config.headers.isToken
  // 是否需要防止数据重复提交
  // const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && isToken) {
    config.headers['Authorization'] = 'Bearer' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  Promise.reject(error)
})

service.interceptors.response.use(
  response => {
    const code = response.data.code || 0;
    if (code === 0) {
      return response;
    }
    else if (code === 1100 || code === 1101 || code === 1102) {
      ElMessage.error(errorCode[code]);
      removeToken()
      removeUser()
      removeFileFormat()
      removeTemperature()
      location.href = '/login';
    }
    else if (errorCode[code]) {
      ElMessage.error(errorCode[code]);
    }
    else {
      ElMessage.error('服务异常');
    }
    return response;
  }
)

// 响应拦截器
// service.interceptors.response.use(res => {

// 未设置状态码则默认成功状态
// const code = res.data.code || 0;
// 获取错误信息
// const msg = errorCode[code] || res.data.msg || errorCode['default']

// if(code == -1){
//   ElMessage.error('失败')
//   return
// }

// })


// 响应拦截器
// service.interceptors.response.use(res => {
//     // 未设置状态码则默认成功状态
//     const code = res.data.code || 200;
//     // 获取错误信息
//     const msg = errorCode[code] || res.data.msg || errorCode['default']
//     if(msg.indexOf('令牌不正确') != -1){
//       // store.dispatch('LogOut').then(() => {
//       //   location.href = '/login';
//       // })
//       return
//     }
//     // 二进制数据则直接返回
//     if(res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer'){
//       return res.data
//     }
//     if (code === 401) {
//       if (!isRelogin.show) {
//         isRelogin.show = true;
//         MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
//           confirmButtonText: '重新登录',
//           cancelButtonText: '取消',
//           type: 'warning'
//         }
//       ).then(() => {
//         isRelogin.show = false;
//         // store.dispatch('LogOut').then(() => {
//         //   location.href = '/index';
//         // })
//       }).catch(() => {
//         isRelogin.show = false;
//       });
//     }
//       return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
//     } else if (code === 500) {
//       Message({
//         message: msg,
//         type: 'error'
//       })
//       return Promise.reject(new Error(msg))
//     } else if (code !== 200) {
//       Notification.error({
//         title: msg
//       })
//       return Promise.reject('error')
//     } else {
//       return res.data
//     }
//   },
//   error => {
//     let { message } = error;
//     if (message == "Network Error") {
//       message = "后端接口连接异常";
//     }
//     else if (message.includes("timeout")) {
//       message = "系统接口请求超时";
//     }
//     else if (message.includes("Request failed with status code")) {
//       message = "系统接口" + message.substr(message.length - 3) + "异常";
//     }
//     Message({
//       message: message,
//       type: 'error',
//       duration: 5 * 1000
//     })
//     return Promise.reject(error)
//   }
// )

export default service
