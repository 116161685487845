import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import pinia from './store'
import ElementPlus from 'element-plus';

// 分页组件
import 'element-plus/theme-chalk/index.css';
import "@/assets/css/style.css";
import './permission'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import locale from 'element-plus/lib/locale/lang/zh-cn'
// 分页组件
import Pagination from '@/components/desk/Pagination'
import Vant from 'vant';
import '../node_modules/vant/lib/index.css';
import './assets/font_chat/iconfont.js';

// markdown
import hljs from 'highlight.js';
import javascript from 'highlight.js/lib/languages/javascript';
// //导入样式
import 'highlight.js/styles/atom-one-light.css' //样式

// import Prism from 'prismjs';

const app = createApp(App)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// //创建v-highlight全局指令
hljs.registerLanguage('javascript', javascript);
app.directive('highlight', function (el) {
    let blocks = el.querySelectorAll('pre code');
    // Array.prototype.forEach.call(blocks, hljs.highlightBlock);
    blocks.forEach((block) => {
        hljs.highlightBlock(block)
    })
})


// Prism.registerLanguage('javascript', javascript);
// app.directive('highlight', function (el) {
//     let blocks = el.querySelectorAll('pre code');
//     // Array.prototype.forEach.call(blocks, hljs.highlightBlock);
//     blocks.forEach((block) => {
//         Prism.highlightElement(block)
//     })
// })
// app.directive('highlight', {
//     mounted(el, binding) {
//         Prism.highlightElement(el);
//     },
// });

app.component('Pagination', Pagination)
app.use(router)
app.use(Vant)
app.use(ElementPlus, { locale })
app.use(pinia)

app.mount('#app')


