import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'

const UserKey = 'User-Info'

const ExpiresInKey = 'Admin-Expires-In'

const FileFormatKey= 'File-Format'

const TemperatureKey= 'Temperature'


export function getUser() {
  return Cookies.get(UserKey)
}

export function setUser(user) {
  return Cookies.set(UserKey, user)
}

export function removeUser() {
  return Cookies.remove(UserKey)
}


export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}


export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getExpiresIn() {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn(time) {
  return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn() {
  return Cookies.remove(ExpiresInKey)
}

export function getFileFormat() {
  return Cookies.get(FileFormatKey)
}

export function setFileFormat(file) {
  return Cookies.set(FileFormatKey, file)
}

export function removeFileFormat() {
  return Cookies.remove(FileFormatKey)
}

export function getTemperature() {
  return Cookies.get(TemperatureKey)
}

export function setTemperature(temperature) {
  return Cookies.set(TemperatureKey, temperature)
}

export function removeTemperature() {
  return Cookies.remove(TemperatureKey)
}

export function setCookie(name,value,perpetual) {
  let exdate = new Date()
  exdate.setDate(exdate.getDate() + perpetual)  //exdate.setDate(exdate.getDate() + 365)
  document.cookie = name + '=' + value + ';expires=' + exdate.toGMTString()  
}

export function getCookie (name) {
  if (document.cookie.length > 0) {
    var start = document.cookie.indexOf(name + '=')
    if (start !== -1) {
      start = start + name.length + 1
      let end = document.cookie.indexOf(';', start)
      if (end === -1) end = document.cookie.length
      return unescape(document.cookie.substring(start, end))
    }
  }
  return ''
}

export function getData(){
  let str=''
  
  for (let i=0;i<10;i++)  {
    let name='permission'+i
    var user=getCookie(name);
    if (user!="") {
      str+=(','+user)
    }
}
}

export function removeCookie(name){
  return Cookies.remove(name)
}

