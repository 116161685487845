<template>
  <aside>
    <div class="search">
      <el-input
        prefix-icon="Search"
        @keyup.enter.native="onSearch"
        @clear="onSearch"
        v-model="searchForm.conversationName"
        clearable
        placeholder="请输入查询内容"
      ></el-input>
    </div>
    <div class="conversations">
      <div class="add-btn" @click="dialogVisible = true">
        <i class="iconfont">&#xe727;</i> 新建会话
      </div>
      <div class="thread-list">
        <div
          class="thread"
          :class="{ active: isFocus == item.id }"
          @click="handleChoose($event, item.id)"
          v-for="(item, index) in conversationsArr"
          :key="index"
        >
          <div class="details">
            <div class="user-head">
              <img
                :class="{ small: item.icons.length > 1 }"
                v-for="(child, index) in item.icons.slice(0, 4)"
                :key="index"
                :src="child ? imgUrl + child : ''"
              />
            </div>
            <div class="user-name" v-if="currentId != item.id">
              <span> {{ item.name }}</span>
              <div class="last">
                <i
                  class="iconfont"
                  title="编辑"
                  @click="handleEdit($event, item.id)"
                  >&#xe621;</i
                >
                <i
                  class="iconfont"
                  title="导出会话"
                  @click="handleExport($event, item.id, item.name)"
                  >&#xe622;</i
                >
                <i class="iconfont" title="删除" @click="handleDelete(item.id)"
                  >&#xe620;</i
                >
              </div>
            </div>
            <div class="user-name" v-else>
              <el-input v-model="item.name" ref="nameRef" />
              <i
                class="iconfont"
                title="保存"
                @click="saveTitle($event, item.id, item.name)"
                >&#xe61e;</i
              >
              <i class="iconfont" title="取消" @click="cancelTitle($event)"
                >&#xe61f;</i
              >
            </div>
            <div class="last-message">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </aside>
  <main>
    <chat-main></chat-main>
  </main>
  <el-dialog
    title="新建会话"
    v-model="dialogVisible"
    width="800px"
    :before-close="handleClose"
  >
    <el-form
      ref="conversationRef"
      :model="conversationForm"
      :rules="rules"
      @submit.native.prevent
    >
      <el-form-item class="form-item" prop="conversationName">
        <el-input
          v-model="conversationForm.conversationName"
          placeholder="会话标题"
          @keyup.enter="handleAdd(conversationRef)"
        ></el-input>
      </el-form-item>
      <el-form-item class="form-item" prop="robotIds">
        <el-checkbox-group v-model="conversationForm.robotIds">
          <el-checkbox
            class="el-checkbox-width"
            :label="item.id"
            v-for="item in robotArr"
            :key="item.id"
          >
            <img :src="item.icon ? baseUrl + item.icon : ''" />
            {{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <!-- <el-form-item class="form-item" prop="isLocalKnow">
        <el-switch
          v-model="conversationForm.isLocalKnow"
          active-color="#297fff"
          active-value="1"
          inactive-value="0"
          active-text="使用本地知识库"
        >
        </el-switch>
      </el-form-item> -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAdd(conversationRef)"
          >确 定</el-button
        >
      </div>
    </el-form>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from "vue";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import { ChatMain } from "@/components/desk";
import {
  getConversation,
  getRobots,
  addConversation,
  editConversation,
  deleteConversation,
  exportConversation,
} from "@/api/chat";
const router = useRouter();
const baseUrl = process.env.VUE_APP_BASE_URI;
const imgUrl = process.env.VUE_APP_BASE_URI + "/media/";
let conversationsArr = ref([]);
let dialogVisible = ref(false);
let robotArr = ref([]);
let currentId = ref();
let isFocus = ref();
const nameRef = ref(null);
const conversationRef = ref();
let searchForm = ref({
  conversationName: "",
});
let conversationForm = ref({
  robotIds: [],
  isLocalKnow: "0",
  conversationName: "",
});
const rules = {
  robotIds: [
    {
      type: "array",
      required: true,
      message: "请选择聊天机器人",
      trigger: "change",
    },
  ],
  conversationName: [
    { required: true, trigger: "blur", message: "请输入聊天标题" },
  ],
};
onMounted(() => {
  getRobotsList();
});
watch(
  () => router.currentRoute.value,
  (newValue) => {
    if (newValue.query.id != undefined) {
      isFocus.value = newValue.query.id;
    }
    getList();
  },
  { immediate: true }
);
function handleClose() {
  dialogVisible.value = false;
  conversationForm.value = {
    robotIds: [],
    isLocalKnow: "0",
    conversationName: "",
  };
}
function getList() {
  getConversation(searchForm.value).then((res) => {
    conversationsArr.value = res.data.data.rows;
    if (
      router.currentRoute.value.query.id == undefined &&
      conversationsArr.value.length
    ) {
      router.push("conversation?id=" + res.data.data.rows[0].id);
    }
  });
}
function saveTitle(event, id, name) {
  event.stopPropagation();
  let params = {
    conversationName: name,
    conversationId: id,
  };
  editConversation(params).then((res) => {
    if (res.data.code == 0) {
      currentId.value = "";
      getList();
    }
  });
}
function cancelTitle(event) {
  event.stopPropagation();
  currentId.value = "";
}
function handleEdit(event, id) {
  event.stopPropagation();
  currentId.value = id;
  nextTick(() => {
    nameRef.value[0].focus();
  });
}
function onSearch() {
  getList();
}
function getRobotsList() {
  getRobots().then((res) => {
    robotArr.value = res.data.data.rows;
    conversationForm.value.robotIds = [res.data.data.rows[0].id];
  });
}
function handleChoose(event, id) {
  event.stopPropagation();
  isFocus.value = id;
  currentId.value = "";
  router.push("/conversation?id=" + id);
}

const handleAdd = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid) => {
    if (valid) {
      conversationForm.value.robotIds = conversationForm.value.robotIds.join();
      addConversation(conversationForm.value).then((res) => {
        if (res.data.code == 0) {
          dialogVisible.value = false;
          conversationForm.value = {
            robotIds: [robotArr.value[0].id],
            isLocalKnow: "0",
            conversationName: "",
          };
          router.push("/conversation?id=" + res.data.data.conversationId);
          getList();
        }
      });
    }
  });
};

function handleExport(event, id, name) {
  event.stopPropagation();
  exportConversation(id).then((res) => {
    downloadTxt(name + ".txt", res.data);
  });
}
function downloadTxt(fileName, content) {
  let blob = new Blob([content], {
    type: "text/plain;charset=utf-8",
  });
  let reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = function (e) {
    let a = document.createElement("a");
    a.download = fileName;
    a.href = e.target.result;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
}
function handleDelete(id) {
  event.stopPropagation();
  deleteConversation(id).then((res) => {
    if (res.data.code == 0) {
      ElMessage.success("会话删除成功");
      getList();
      let arr = conversationsArr.value;
      if (id == router.currentRoute.value.query.id) {
        router.push("conversation?id");
      } else {
        if (id == arr[0].id) {
          router.push("conversation?id=" + arr[1].id);
        } else {
          router.push("conversation?id=" + arr[0].id);
        }
      }
    }
  });
}
</script>
<style scoped>
.el-input {
  height: 36px;
}

.search {
  height: 60px;
  padding: 12px 20px;
}

.form-item {
  margin-bottom: 25px;
  text-align: left;
}
.form-item .el-input {
  height: 48px;
}
.conversations {
  height: calc(100% - 80px);
  width: 100%;
  position: relative;
}
.conversations .thread {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background: rgba(234, 234, 234, 0.1);
  border-radius: 8px;
  margin-bottom: 1px;
}
.conversations .thread.active,
.conversations .thread:hover {
  background: rgba(41, 127, 255, 0.1);
}
.conversations .thread-list {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 20px);
}
.conversations .thread .details {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "user-image user-name" "user-image last-message";
  margin: 17px;
  align-items: center;
}
.conversations .thread .details .user-head {
  grid-area: user-image;
  margin-right: 20px;
  line-height: 1;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.conversations .thread .details .user-name,
.conversations .thread .details .last-message {
  width: 280px;
}
.conversations .thread .details .user-name {
  grid-area: user-name;
  color: #000000;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  display: flex;
  height: 22px;
  line-height: 22px;
}
.conversations .thread .details .user-name i {
  font-size: 24px;
  margin: 2px 0 0 5px;
  color: #999;
  font-weight: normal;
}
::v-deep .conversations .thread .details .user-name .el-input__wrapper {
  height: 28px;
  background: #fff;
}
.conversations .thread .details .user-name span {
  flex: 1;
}
.conversations .thread .details .last-message {
  grid-area: last-message;
  color: #999999;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.conversations .thread .last {
  height: 22px;
  width: 72px;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  border-radius: 11px;
  display: flex;
  justify-content: space-around;
  display: none;
}
.conversations .thread .last i {
  color: #7c848a;
}
.conversations .thread.active .last,
.conversations .thread:hover .last {
  display: flex;
}
.el-checkbox-group {
  text-align: left;
}
::v-deep .el-checkbox__label img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
::v-deep .el-checkbox-width {
  width: 170px;
  height: 25px;
  margin-bottom: 15px;
}
.dialog-footer {
  text-align: right;
}
.conversations .thread .last img {
  width: 16px;
  height: 16px;
}
.conversations .clear-conver {
  font-size: 16px;
  color: #ffffff;
  background-color: #ffffff;
  position: absolute;
  height: 60px;
  line-height: 60px;
  width: 100%;
  bottom: 21px;
  text-align: left;
  text-indent: 20px;
  cursor: pointer;
  color: #000000;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}
.conversations .clear-conver img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  vertical-align: middle;
}
.conversations .export-conver {
  font-size: 16px;
  color: #000000;
  background-color: #ffffff;
  position: absolute;
  height: 60px;
  line-height: 60px;
  width: 100%;
  bottom: 85px;
  text-align: left;
  text-indent: 20px;
  cursor: pointer;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.conversations .export-conver img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  vertical-align: middle;
}
</style>
