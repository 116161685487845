<template>
  <div class="logo">
    <router-link to="/">
      <img src="@/assets/img/logo.png" />
    </router-link>
  </div>
</template>
<style scoped>
.logo {
  height: 60px;
  line-height: 60px;
  padding: 0px 6px;
  text-align: center;
}
.logo img {
  width: 30px;
  display: inline-block;
  vertical-align: middle;
}
</style>
