import { createRouter, createWebHashHistory } from 'vue-router';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import conversation from "../views/conversation.vue";

const routes = [
  {
    path: '/', // 如果路由为/
    redirect: '/conversation' //重定向到登录组件
  }, {
    path: "/conversation",
    name: "conversation",
    component: () =>
      import(/* webpackChunkName: "addconversation" */ "../views/conversation.vue"),
    // component: conversation,
  }, {
    path: "/conversation/add",
    name: "addconversation",
    component: () =>
      import(/* webpackChunkName: "addconversation" */ "../views/m/addconversation.vue"),
  }, {
    path: "/conversation/detail",
    name: "detailconversation",
    component: () =>
      import(/* webpackChunkName: "detailconversation" */ "../views/m/detailconversation.vue"),
  }, {
    path: "/conversation/edit",
    name: "editconversation",
    component: () =>
      import(/* webpackChunkName: "editconversation" */ "../views/m/editconversation.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  }, {
    path: "/person",
    name: "person",
    component: () =>
      import(/* webpackChunkName: "person" */ "../views/m/person.vue"),
  }, {
    path: "/updatepwd",
    name: "updatepwd",
    component: () =>
      import(/* webpackChunkName: "updatepwd" */ "../views/m/updatepwd.vue"),
  },
];

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// });

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  NProgress.start() // 进度条开始
  next()
})

router.afterEach((to, from) => {
  NProgress.done() // 进度条结束
})

export default router;
