<template>
  <div class="loading" v-if="loading"><van-loading color="#1989fa" /></div>
  <div v-else>
    <div class="top-bar">
      <el-input
        @clear="onSearch"
        @keyup.enter.native="onSearch"
        v-model="searchForm.conversationName"
        clearable
        placeholder="请输入搜索关键词"
      >
        <template #prepend>
          <el-button link :icon="Search" @click="onSearch" />
        </template> </el-input
      >&nbsp;
      <el-button type="primary" :icon="Plus" @click="handleAdd" />
    </div>
    <div class="conversations">
      <message-list
        :lists="conversationsArr"
        @jump="jumpPaper"
        @delete="deleteThis"
      ></message-list>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, nextTick } from "vue";
import { MessageList } from "@/components/mobile";
import { getConversation } from "@/api/chat";
import { useRouter } from "vue-router";
import { Search } from "@element-plus/icons-vue";
import { Plus } from "@element-plus/icons-vue";
let conversationsArr = ref([]);
let searchForm = ref({
  conversationName: "",
});
const loading = ref(false);
const router = useRouter();
onMounted(() => {
  getList();
});

// 是否登录后第一次加载
const checkFirst = () => {
  if (localStorage.getItem("isFirst") == "true") {
    localStorage.removeItem("isFirst");
    // 读取第一个列表
    const id = conversationsArr.value[0] && conversationsArr.value[0].id;
    router.replace({ path: "/conversation/detail", query: { id: id } });
  }
};

function jumpPaper(data) {}
function deleteThis(data) {}

function handleAdd() {
  router.push("/conversation/add");
}

function getList() {
  loading.value = true;
  getConversation(searchForm.value)
    .then((res) => {
      conversationsArr.value = res.data.data.rows;
    })
    .finally(() => {
      checkFirst();
      setTimeout(() => {
        loading.value = false;
      }, 10);
    });
}
function onSearch() {
  getList();
}
</script>
<style scoped>
::v-deep .el-input .el-input__wrapper {
  background: #ffffff;
}
::v-deep .el-input-group__prepend {
  background: #ffffff;
  box-shadow: none;
}

.loading {
  position: absolute;
  top: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete {
  height: 100%;
  text-align: center;
  width: 65px;
  line-height: 45px;
  color: #fff;
  background-color: #ec132d;
}
.top-bar {
  display: flex;
  padding: 15px 16px;
}
.add-btn {
  width: 320px;
  height: 40px;
  line-height: 40px;
  margin: 0 auto;
  text-align: center;
  border-radius: 4px;
  color: #297fff;
  border: 2px dashed #297fff;
  margin-bottom: 16px;
  cursor: pointer;
}

.form-item {
  margin-bottom: 25px;
  text-align: left;
}
.form-item .el-input {
  height: 48px;
}
.conversations {
  height: calc(100% - 80px);
  width: 100%;
  position: relative;
  overflow: scroll;
  padding-bottom: 30px;
}
.conversations .thread {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  background: rgba(234, 234, 234, 0.1);
  border-radius: 8px;
  margin-bottom: 1px;
}
.conversations .thread.active,
.conversations .thread:hover {
  background: rgba(41, 127, 255, 0.1);
}
.conversations .thread .details {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "user-image user-name" "user-image last-message";
  margin: 17px;
  align-items: center;
}
.conversations .thread .details .user-head {
  grid-area: user-image;
  margin-right: 20px;
  line-height: 1;
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 8px;
}
.conversations .thread .details .user-head img {
  width: 100%;
}
.conversations .thread .details .user-name,
.conversations .thread .details .last-message {
  width: 260px;
}
.conversations .thread .details .user-name {
  grid-area: user-name;
  color: #000000;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
}
.conversations .thread .details .last-message {
  grid-area: last-message;
  color: #999999;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.conversations .thread .last {
  height: 22px;
  width: 50px;
  text-align: center;
  line-height: 22px;
  font-size: 12px;
  border-radius: 11px;
  position: relative;
  top: 21px;
  right: 17px;
  display: flex;
  justify-content: space-around;
  display: none;
}
.conversations .thread.active .last,
.conversations .thread:hover .last {
  display: flex;
}
.el-checkbox-group {
  text-align: left;
}
::v-deep .el-checkbox-group {
  margin-bottom: 30px;
}
::v-deep .el-checkbox__label img {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}
::v-deep .el-checkbox-width {
  width: 170px;
  height: 25px;
  margin-bottom: 15px;
}
.dialog-footer {
  text-align: right;
}
.conversations .thread .last img {
  width: 16px;
  height: 16px;
}
.conversations .clear-conver {
  font-size: 16px;
  color: #ffffff;
  background-color: #ffffff;
  position: absolute;
  height: 60px;
  line-height: 60px;
  width: 100%;
  bottom: 21px;
  text-align: left;
  text-indent: 20px;
  cursor: pointer;
  color: #000000;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}
.conversations .clear-conver img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  vertical-align: middle;
}
.conversations .export-conver {
  font-size: 16px;
  color: #000000;
  background-color: #ffffff;
  position: absolute;
  height: 60px;
  line-height: 60px;
  width: 100%;
  bottom: 85px;
  text-align: left;
  text-indent: 20px;
  cursor: pointer;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.conversations .export-conver img {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  vertical-align: middle;
}
</style>
