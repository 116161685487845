import service from '@/utils/request'

//登录
export function login(data) {
  return service({
    url: '/user/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 第三方登录
export function caipLogin(data) {
  return service({
    url: '/user/caipLogin',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}


//登出
export function logout() {
  return service({
    url: '/user/logout',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

//修改密码
export function updatePwd(data) {
  return service({
    url: '/user/updatePwd',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

//获取通用话术接口
export function getSpeech(params) {
  return service({
    url: '/chat/getSpeech',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

//获取机器人
export function getRobots(params) {
  return service({
    url: '/chat/getRobots',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

//新建会话
export function addConversation(data) {
  return service({
    url: '/chat/addConversation',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

//编辑会话
export function editConversation(data) {
  return service({
    url: '/chat/editConversation',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

//删除会话
export function deleteConversation(id) {
  return service({
    url: `/chat/deleteConversation?conversationId=${id}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

//获取会话列表
export function getConversation(params) {
  return service({
    url: '/chat/getConversation',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}



//获取会话详情
export function conversationDetail(params) {
  return service({
    url: '/chat/conversationDetail',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

//获取知识库列表
export function getKnowledge(params) {
  return service({
    url: '/store/getKnowledge',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

//获取知识库文件列表
export function getFiles(params) {
  return service({
    url: '/store/getFiles',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

//文件加入矢量库
export function addVector(params) {
  return service({
    url: '/store/addVector',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

//上传文件接口
export function uploadKnowledgeFile(params) {
  return service({
    url: '/store/uploadKnowledgeFile',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

//获取提示语列表
export function getPrompts(params) {
  return service({
    url: '/chat/getPrompt',
    headers: {
      isToken: true
    },
    method: 'get',
    params: params
  })
}

//获取提示语列表
export function getTemperature() {
  return service({
    url: '/chat/TemperatureOptions',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

//获取提示语列表
export function setPrompt(id, data) {
  return service({
    url: `/chat/setPrompt?conversationId=${id}`,
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

//清除当前会话信息
export function clearMessage(data) {
  return service({
    url: '/chat/clearMessage',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

//导出当前会话信息
export function exportConversation(id) {
  return service({
    url: `/chat/exportConversation?conversationId=${id}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}



//发送消息
export function sendMessage(data) {
  return service({
    url: '/chat/sendMessage',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

//发送消息（流式输出）
export function messageStream(data) {
  return service({
    // url: '/chat/messageStream',
    url: '/common/data',
    headers: {
      isToken: true,
      responseType: 'stream'
    },
    enableChunked: true,
    method: 'post',
    data: data
  })
}



//消息评价
export function rateMessage(data) {
  return service({
    url: '/chat/rateMessage',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

//消息评价
export function commonOptions() {
  return service({
    url: '/chat/commonOptions',
    headers: {
      isToken: true
    },
    method: 'get'
  })
}

// 数字人------------------------------------------------------
// 获取素材接口
export function dmnGetFiles(params) {
  return service({
    url: '/dmn/getFiles',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 数字人生成接口
export function dmnGenerate(data) {
  return service({
    url: '/dmn/generate',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}

// 取消数字人生成接口
export function dmnCancel(params) {
  return service({
    url: '/dmn/cancel',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 获取文件列表
export function dmnGetOutFiles(params) {
  return service({
    url: '/dmn/getOutFiles',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 删除数字人视频接口
export function dmnDelOut(params) {
  return service({
    url: '/dmn/delOut',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}






// 获取文件列表
export function dmGetFiles(params) {
  return service({
    url: '/dm/getFiles',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}
//新建文件夹
export function dmMkdir(data) {
  return service({
    url: '/dm/mkdir',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}
//文件重命名
export function dmRename(data) {
  return service({
    url: '/dm/rename',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}
//删除文件/文件夹
export function dmDelete(params) {
  return service({
    url: '/dm/delete',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}
// 上传文件
export function dmUpload() {
  return service({
    url: '/dm/upload',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}
// 获取输出文件
export function dmGetOutFiles(params) {
  return service({
    url: '/dm/getOutFiles',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}
// 删除输出文件
export function dmDelOut(params) {
  return service({
    url: '/dm/delOut',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}
// 训练(声音模型)/转语音/转视频
export function dmTrain(data) {
  return service({
    url: '/dm/train',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}
// 转语音
export function dmChangeAudio(data) {
  return service({
    url: '/dm/changeAudio',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}
// 转视频
export function dmChangeVideo(data) {
  return service({
    url: '/dm/changeVideo',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}
// 数字人----------------------------------------

// PDF------------------------------------------
// 获取文件列表
export function getPdfFiles(params) {
  return service({
    url: '/pdftrans/getFiles',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 新建文件夹
export function addPdftrans(data) {
  return service({
    url: '/pdftrans/mkdir',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}

// 重命名
export function RenamePdftrans(data) {
  return service({
    url: '/pdftrans/rename',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}

// 删除文件/文件夹
export function delPdftrans(params) {
  return service({
    url: '/pdftrans/delete',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 上传文件
export function uploadPdftrans(data) {
  return service({
    url: '/pdftrans/upload',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}

// 获取输出文件
export function getPdfOutFiles(params) {
  return service({
    url: '/pdftrans/getOutFiles',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 删除输出文件
export function delOutPdftrans(params) {
  return service({
    url: '/pdftrans/delOut',
    headers: {
      isToken: true
    },
    method: 'get',
    params
  })
}

// 翻译
export function translatePdf(data) {
  return service({
    url: '/pdftrans/translate',
    headers: {
      isToken: true
    },
    method: 'post',
    data
  })
}
// PDF----------------------------

